import React, { useState, useEffect } from 'react'
import { Layout } from '../../components/layout'
import { useEventReports } from '../../hooks/useEventReports'
import { EventReports } from '../../components/event-reports'
import { Article } from '../../components/article'
import { EventReport as EventReportType } from '../../types/event-report'
import { SEO } from '../../components/seo'

const EventReportPage = ({ params }: any) => {
	const { eventReports } = useEventReports()
	const [eventReport, setEventReport] = useState<EventReportType | null>(null)

	useEffect(() => {
		const eventReport = eventReports.find(eventReport => eventReport.title === params._title)
		if (eventReport) {
			setEventReport(eventReport)
		}
	}, [params, eventReports])

	return (
		<Layout>
			<SEO
				title={`${params._title}  ${eventReport ? '> ' + eventReport.categories[0].title : '' } | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description={eventReport && eventReport.content.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')}
				keywords={['メキシコ料理', '渋谷' , 'ファインメキシカン', 'ルビア', 'sarasa']}
			/>
			<h1 className="h1">{params._title} | RUBIA ルビア / DJ sarasa</h1>
			<div className="story">
				{eventReport && (
					<>
						<Article type="eventReport" article={eventReport} />
						<EventReports />
					</>
				)}
			</div>
		</Layout>
	)
}

export default EventReportPage
